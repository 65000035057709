.input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;

  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
  }

  & label {
    margin-bottom: 0;
    position: relative;
    line-height: 18px;
    font-weight: 500;

    & a {
      color: #565858;
      text-decoration: underline;
    }

    & span {
      position: absolute;
      top: 15px;
      font-size: 10px;
    }
  }
}

.carrier-group {
  margin-top: 20px;
  padding: 0 10px;

  &:first-child {
    padding-top: 10px;
  }

  &.intact .logo a {
    margin-right: 10px;
  }

  a {
    text-decoration: underline;
    color: $main-color;
    &:hover {
      text-decoration: underline;
    }
  }

  & .logo {
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }

    & a {
      margin-right: 5px;
    }

    & img {
      max-width: 175px;
      width: 100%;
    }

    & span {
      font-size: 11px;
      font-style: italic;
      font-weight: 400;
      color: $neutral-color;
    }
  }

  & .price-block {
    margin-top: 15px;
    font-weight: 500;

    & span {
      color: $main-color;
      font-weight: 600;
    }
  }

  & .info-block {
    font-size: 12px;
  }

  & .sports-block {
    margin-top: 5px;
  }

  & .result-wrapper {
    display: flex;
    font-size: 13px;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }

    & .column {
      width: 50%;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      & .value-block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 5px;

        & .checkmark {
          font-size: 20px;
          width: 25px;

          &.green {
            color: $green;
          }

          &.orange {
            color: $orange;
          }
        }

        & .value {
          width: 250px;

          @include media-breakpoint-down(xs) {
            width: 100%;
          }
        }
      }
    }
  }
}

.popover-content {
  font-size: 0.75rem;
}
