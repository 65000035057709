.custom-radio {
  .custom-control-label {
    font-size: 15px;
    font-weight: normal;
    padding-left: 2px;

    &::before {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      border-color: $main-color;
      top: 4px;
      left: -21px;
    }
    &::after {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      left: -18px;
      top: 7px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border-color: $main-color;
      background-color: $white;
    }
    &::after {
      background-image: none;
      background-color: $main-color;
    }
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px $main-color;
  }
}
