body,
html {
  margin: 0;
  padding: 0;

  & #page-wrapper {
    padding: 9px;
    box-sizing: border-box;
    width: 656px;
    font-size: 15px;
    color: $neutral-color;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    & .calculations-wrapper {
      background-color: $background-color;
      padding-bottom: 10px;
    }

    & .calculator-wrapper {
      background-color: $background-color;
      position: relative;
      border-radius: 5px;

      & .inner-wrapper {
        display: flex;

        @include media-breakpoint-down(sm) {
          width: 100%;
          display: block;
        }
      }

      & .event-type {
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        width: 340px;
        height: 30px;
      }

      @include media-breakpoint-down(sm) {
        padding-bottom: 30px;
      }

      @include media-breakpoint-down(xs) {
        padding-bottom: 55px;
      }
    }

    & .button-place {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin: 20px 0;
    }
  }

  h4 {
    font-size: 15px;
    color: $main-color;
    font-weight: 500;
    padding: 5px;
  }

  & .w-110 {
    width: 110px;
  }

  & .w-163 {
    width: 163px;
  }

  & .small-column {
    width: 296px;

    & .label {
      width: 163px;

      @include media-breakpoint-down(sm) {
        width: calc(50% - 30px);
      }

      @include media-breakpoint-down(xs) {
        width: calc(100% - 30px);
      }
    }

    & .input {
      width: 96px;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  & .big-column {
    width: 342px;

    & .label {
      width: 163px;

      @include media-breakpoint-down(sm) {
        width: calc(50% - 30px);
      }

      @include media-breakpoint-down(xs) {
        width: calc(100% - 30px);
      }
    }

    & .input {
      width: 144px;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .submit-button {
    background-color: $main-color;
    border-radius: 3px;
    border: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 15px;
    font-weight: 500;

    &:hover {
      background-color: $main-color-light;
    }
  }

  .async-button {
    &__spinner {
      & .spinner-border {
        width: 1rem;
        height: 1rem;
        border-width: 0.2em;
        margin-right: 0.25rem;
      }
    }
  }

  .back-to-form-button {
    border: 2px solid $main-color;
    color: $main-color;
    background-color: $white;
    border-radius: 3px;
    font-weight: 500;
    font-size: 15px;
    padding: 0.12rem 0.75rem;

    &:hover {
      background-color: $main-color;
      color: $white;
    }
  }

  .toggle-button {
    padding: 0;
    margin-top: 8px;
    text-decoration: underline;
    color: $main-color;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }

  .popover {
    color: $main-color;
    font-weight: 400;
    padding: 2px;
  }

  & .disclaimer {
    font-size: 11px;
    font-style: italic;
    margin-top: 8px;
    color: $neutral-color;
  }

  & .padding-15-on-mobile {
    @include media-breakpoint-down(xs) {
      padding-bottom: 15px;
    }
  }
}
